<div class="header-topnav text-light" style="padding-bottom: 4px;z-index: 999;" fxFlex.sm="100"  fxFlex.xs="100">
  <header class="main-header main-header--one  clearfix">
    <div class="main-header--one__top clearfix">
        <div class="container">
            <div class="main-header--one__top-inner clearfix">
                <div class="main-header--one__top-left">
                    <div class="main-header--one__top-logo">
                        <a href="index.html"><img src="assets/new/images/resources/logo-1.png" alt="{{seoService.getLogoAltText()}}" /></a>
                    </div>
                </div>

                <div class="main-header--one__top-right clearfix">
                    <ul class="main-header--one__top-social-link list-unstyled clearfix">
                        <li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
                        <li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>

                    </ul>

                    <div class="main-header--one__top-contact-info clearfix">
                        <ul class="main-header--one__top-contact-info-list list-unstyled">
                            <li class="main-header--one__top-contact-info-list-item">
                                <div class="icon">
                                    <span class="icon-message"></span>
                                </div>
                                <div class="text">
                                    <h6>Contáctanos</h6>
                                    <p><a href="mailto:atencion@fundacione.org">atencion@fundacione.org</a></p>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="main-header-one__bottom clearfix">
        <div class="container">
            <div class="main-header-one__bottom-inner clearfix">
                <nav class="main-menu main-menu--1">
                    <div class="main-menu__inner">
                        <!--<a href="#" class="mobile-nav__toggler"><i class="fa fa-bars"></i></a>-->
                        <div fxFlex="100" fxLayoutAlign="space-between center" style="z-index: 9999;">
                            <div fxFlex="100" fxLayoutAlign="start center">
                              <a class="mobile-nav__toggler" (click)="showMenu = !showMenu" style="margin-left: 20px;">
                                <i class="fa fa-bars"></i>
                              </a>
                            </div>
                        </div>                            
                        <div class="left">
                            <ul class="main-menu__list">
                                <li><a [ngClass]="getActiveRouteClass(HOME_ROUTE)" (click)="goTo(HOME_ROUTE)">Inicio</a></li>
                                <li><a [ngClass]="getActiveRouteClass(COURSES_NO_AUTH_ROUTE)" (click)="goTo(COURSES_NO_AUTH_ROUTE)">Cursos</a></li>
                                <li><a [ngClass]="getActiveRouteClass(ABOUT_ROUTE)" (click)="goTo(ABOUT_ROUTE)">¿Quiénes somos?</a></li>
                                <li><a [ngClass]="getActiveRouteClass(BLOG_ROUTE)" (click)="goTo(BLOG_ROUTE)">Blog</a></li>
                                <li><a [ngClass]="getActiveRouteClass(CONTACT_ROUTE)" (click)="goTo(CONTACT_ROUTE)">Contáctanos</a></li>
                            </ul>
                        </div>

                        <div class="right">
                            <div class="main-menu__right">
                                <div class="main-menu__right-login-register">
                                    <ul class="list-unstyled">
                                        <li><a [ngClass]="getActiveRouteClass('/signin')" [routerLink]="['/signin']">Iniciar sesión</a></li>
                                        <li><a [ngClass]="getActiveRouteClass('/signup')" [routerLink]="['/signup']">Regístrate</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </nav>

            </div>
        </div>
    </div>
  </header>
</div>
<div 
    *ngIf="showMenu" 
    [@animate]="{value:'*',params:{delay: '100ms', x:'-250px'}}" class="sidebar-panel" style="margin-top: 190px;background-color: var(--thm-black);"
    >
    <app-sidenav style="color: white !important;" [items]="menuItems"></app-sidenav>  
</div>