const API = {
  PROD:true,
  
  //PRODUCCIÓN@2
  // URL_BASE: 'https://my4x4academy.com/entrepreneur44API/public/api/',
  // URL_BASE_STORAGE: 'https://my4x4academy.com/entrepreneur44API/public',
  // URL_BASE_FRONT: 'https://my4x4academy.com/',//url base de angular -> al subir a servidor cambiar parámetro
  // ECHO_BROADCASTER: 'pusher',
  // ECHO_CLUSTER: 'us2',
  // ECHO_KEY: '5c2ebe96a7a06147f0d3',
  // ECHO_AUTH_END_POINT: 'https://my4x4academy.com/',
  // ECHO_HOST: 'my4x4academy.com',
  // ECHO_WS_HOST: 'my4x4academy.com',
  // ECHO_WS_PORT: 6001,
  // ECHO_PORT: 6001,

  //PRODUCCIÓN
  // URL_BASE: 'https://elearningfundacione.org/entrepreneur44API/public/api/',
  // URL_BASE_STORAGE: 'https://elearningfundacione.org/entrepreneur44API/public/',
  // URL_BASE_FRONT: 'https://elearningfundacione.org/',//url base de angular -> al subir a servidor cambiar parámetro
  
  // ECHO_BROADCASTER: 'pusher',vcalendar
  // ECHO_CLUSTER: 'us2',
  // ECHO_KEY: '5c2ebe96a7a06147f0d3',
  // ECHO_AUTH_END_POINT: 'https://elearningfundacione.org/',
  // ECHO_HOST: 'elearningfundacione.org',
  // ECHO_WS_HOST: 'elearningfundacione.org',
  // ECHO_WS_PORT: 6001,
  // ECHO_PORT: 6001,

  // QA webhop
  /* URL_BASE: 'http://celex.webhop.net/entrepreneur44API/public/api/',
  URL_BASE_STORAGE: 'http://celex.webhop.net/entrepreneur44API/public', //     http://celex.webhop.net/entrepreneur44API/storage/img/Topic/53/sample.pdf
  URL_BASE_FRONT: 'http://celex.webhop.net/entrepreneur44/',//url base de angular -> al subir a servidor cambiar parámetro
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: '5c2ebe96a7a06147f0d3',
  ECHO_AUTH_END_POINT: 'http://celex.webhop.net',
  ECHO_HOST: 'celex.webhop.net',
  ECHO_WS_HOST: 'celex.webhop.net',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001, */

  // Local http artisan
  /* URL_BASE: 'http://127.0.0.1:8000/api/',
  URL_BASE_STORAGE: 'http://127.0.0.1:8000/',
  URL_BASE_FRONT: 'http://localhost:4200/',//url base de angular -> al subir a servidor cambiar parámetro
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: 'f69674f54331107e67c5',
  ECHO_AUTH_END_POINT: 'http://127.0.0.1:8000/',
  ECHO_HOST: '127.0.0.1',
  ECHO_WS_HOST: '127.0.0.1',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001, */
  
  // local
  /* URL_BASE: 'http://localhost:8080/entrepreneur44API/public/api/',
  URL_BASE_STORAGE: 'http://localhost:8080/entrepreneur44API/public/',
  URL_BASE_FRONT: 'http://localhost:4200/',//url base de angular -> al subir a servidor cambiar parámetro
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: 'f69674f54331107e67c5',
  ECHO_AUTH_END_POINT: 'http://localhost:8080/',
  ECHO_HOST: 'localhost',
  ECHO_WS_HOST: 'localhost',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001, */

  /* URL_BASE: 'https://kodezilla.com/4x4/services/public/api/',
  URL_BASE_STORAGE: 'https://kodezilla.com/4x4/services/public',
  URL_BASE_FRONT: 'https://kodezilla.com/4x4/web',
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: '5c2ebe96a7a06147f0d3',
  ECHO_AUTH_END_POINT: 'http://kodezilla.com/',
  ECHO_HOST: 'kodezilla.com',
  ECHO_WS_HOST: 'kodezilla.com',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001, */

  /* URL_BASE: 'http://kodezilla.com/4x4/reloaded/services/public/api/',
  URL_BASE_STORAGE: 'http://kodezilla.com/4x4/reloaded/services/public',
  URL_BASE_FRONT: 'http://kodezilla.com/4x4/reloaded',
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: '5c2ebe96a7a06147f0d3',
  ECHO_HOST: 'kodezilla.com',
  ECHO_WS_HOST: 'kodezilla.com',
  ECHO_WS_PORT: 6004,
  ECHO_PORT: 6004, */

  //PRODUCCIÓN@2
  URL_BASE: 'https://my4x4academy.com/services/public/api/',
  URL_BASE_STORAGE: 'https://my4x4academy.com/services/public',
  URL_BASE_FRONT: 'https://my4x4academy.com/',//url base de angular -> al subir a servidor cambiar parámetro
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: '5c2ebe96a7a06147f0d3',
  ECHO_AUTH_END_POINT: 'https://my4x4academy.com/',
  ECHO_HOST: 'my4x4academy.com',
  ECHO_WS_HOST: 'my4x4academy.com',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001,

}
export{ API };

const ECHO_TEST:any = {
  broadcaster: API.ECHO_BROADCASTER,
  cluster: API.ECHO_CLUSTER,
  key: API.ECHO_KEY,
  wsHost: API.ECHO_WS_HOST,
  wsPort: API.ECHO_WS_PORT,
  disableStats: true,
  enabledTransports: ['ws']
};
export {ECHO_TEST};

const ECHO_PROD:any = {
  broadcaster: API.ECHO_BROADCASTER,
  cluster: API.ECHO_CLUSTER,
  key: API.ECHO_KEY,
  wsHost: API.ECHO_WS_HOST,
  wsPort: API.ECHO_WS_PORT,
  wssPort: API.ECHO_WS_PORT,
  disableStats: true,
  encrypted: true,
  forceTLS: true,
  enabledTransports: ['ws','wss']
};
export {ECHO_PROD};