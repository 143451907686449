import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogTopicComponent } from 'app/shared/components/dialog-topic/dialog-topic.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { DialogOrderTopicResourcesComponent } from 'app/views/dialogs/dialog-order-topic-resources/dialog-order-topic-resources.component';
import { DialogOrderTopicsComponent } from 'app/views/dialogs/dialog-order-topics/dialog-order-topics.component';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { RoleService } from 'app/views/services/role.service';
import { StorageService } from 'app/views/services/storage.service';

@Component({
  selector: 'app-admin-topics',
  templateUrl: './admin-topics.component.html',
  styleUrls: ['./admin-topics.component.css']
})
export class AdminTopicsComponent implements OnInit {
  
  row:any;
  moduloId:number;
  courseId:number;
  idTopic:string;
  displayedColumns: string[] = [ 'topic','description','simulator'];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userInfo:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: AdminService,
    private router: Router,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private paginatorService: PaginatorService,
    private storage:StorageService,
    public role:RoleService
  ) { }

  ngOnInit(): void {
    this.userInfo = this.storage.getUserInfo();
    if (this.role.isAdmin()) {
      this.displayedColumns = ['topic','description','simulator','activeLabel','Borrar','Editar','order'];
    }
    if (this.role.isInstructor()) {
      this.displayedColumns = ['topic','description','simulator'];
    }
    this.moduloId =0; 
    this.courseId=0;
    this.activatedRoute.paramMap.subscribe(params => {
      this.moduloId = Number(params.get('id'));
      this.courseId = Number(params.get('courseId'));
      if (this.moduloId) {
        this.getTopics(this.moduloId);
      } else {
        this.service.openDialog('No fue posible obtener la información del módulo.');
        this.router.navigateByUrl('/dashboard/cmsCourses');
      }
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }

  getTopics(moduloId:number){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getModuleTopics(moduloId).subscribe(
      (data) => {
        switch(data.status){
          case 200:
            //console.log(data.body.content);
            this.dataSource.data =  data.body.content;
            this.loader.close();
            break;
          default:
            this.loader.close();
            this.service.openDialog("No se encontraron datos");
            break;
        }
          
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }
  
  onClickNuevo() {
    let item:any = {
      id : 0,
      topic : '',
      description : '',
      active : 1,
      moduloId : this.moduloId,
      Resource:[],
      moduleId : this.moduloId,
    }
    this.row = item;
    this.openDialog();
  }

  saveTopic(dataSave){
    this.loader.open('Guardando tema ...');
    this.service.createTopic(dataSave).subscribe(
    async (data:any) => {
        this.loader.close();
        const files:any= dataSave.files as [];
        if(files.length>0){
          this.loader.open();
          this.idTopic =  data.content.id;
          for(var index=(files.length-1);index>=0;index--){
            const file = files[index];
              let formData = new FormData();
              formData.append('id',this.idTopic);
              formData.append('titulo',file.titulo);
              formData.append('file',file.file as File);
              let result = await this.saveTopicResource(formData,index,"guardado");
            files.splice(index, 1); 
          }
         
        }
        else{
            this.dialogCongfirm("Tema Guardado Correctamente");
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog("Error en el Servicio1204");
      }
    );
  }

  onClickEdit(item:any){
    this.row =item;
    this.openDialog();
  }

  public openDialog() {
    this.dialog.open(DialogTopicComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe(async (data: any)=> {
        if (data != null) {
          if(this.row.id==0){
            await this.saveTopic(data);
          }else{
            await this.updateTopic(data);
          }
        }
      });
  }

  async updateTopic(dataSave){
    this.loader.open('Actualizando tema ...');
    await this.service.updateTopic(dataSave).subscribe(
      async (data:any)  => {
        this.loader.close();
        const files:any= await dataSave.files as [];
        // filtramos el array
        const arrayFiles :any =[];
        for(var index=(files.length-1);index>=0;index--){
          const file = await files[index];
          if(file.hasOwnProperty("idTemp")){
            arrayFiles.push(file);
          }
        }
        if(arrayFiles.length>0){
          this.loader.open();
          this.idTopic = await  data.content.id;
          // procesamos array filtrado
           for(var index=(arrayFiles.length-1);index>=0;index--){
            const file = await arrayFiles[index];
            if(file.hasOwnProperty("idTemp")){
              let formData = await new FormData();
              await formData.append('id',this.idTopic);
              await formData.append('titulo',file.titulo);
              await formData.append('file',file.file as File);
              await this.saveTopicResource(formData,index,'actualizado');
            }
          }
        }
        else{
          this.dialogCongfirm("Tema actualizado correctamente");
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  async saveTopicResource(formData,index,mensaje){  
    await this.service.saveResourseTopic(formData).subscribe(
      async (data:any) => {
         return data;
      },
      async (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      },
      async ()=> {
        if(index==0){
          this.loader.close();
          this.dialogCongfirm("Tema "+mensaje+" correctamente");
        }
      }
    );
  }

  deleteTopic(data){
    this.row = data;
    this.loader.open('Eliminando Tema ...');
    this.service.deleteModule(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Tema eliminado correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  goToCatModule(){
    this.router.navigate(['/dashboard/cmsModule',this.courseId]);
  }

  onClickDelete(item:any){
    this.row =item;
    this.openDialogConfirm("Eliminar","¿Desea eliminar tema?",true);
  }

  public openDialogConfirm(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteTopic(this.row);
        } 
      });
  }

  openConfirm(message:string){
    return this.dialog.open(DialogConfirmComponent,{
      data:{message:message,confirm:false},
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }

  dialogCongfirm(message:string){
    this.openConfirm(message)
    .then(
      (response) => {
        this.getTopics(this.moduloId);
      }
    )
    .catch(
      (error) => {
        this.service.openDialog(`Error al: ${message}`)
      }
    );
  }

  openDialogOrderTopics() {
    let topics:any[] = [];
    topics = this.dataSource.data;
    if (topics.length <= 0) {
      this.service.openDialog(`No hay temas para ordenar`).subscribe(
        () => {this.getTopics(this.moduloId);}
      )
    } else {
      this.dialog.open(DialogOrderTopicsComponent,{
        data:{
          topics:topics
        },autoFocus:false
      }).afterClosed().subscribe(
        (response) => {
          if (response) {
            this.updateOrderTopics(response);
          }
        }
      );
    }
  }

  updateOrderTopics(orderedTopics:any) {
    let data = {
      orderedTopics:orderedTopics
    };
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.updateOrderTopics(data).subscribe(
      (response:any) => {
        loader.close();
        this.service.openDialog(response.content.message).subscribe(
          () => {this.getTopics(this.moduloId);}
        );
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  openDialogOrderResources(topic:any) {
    let resources:any[] = [];
    resources = topic.Resource;
    resources = this.service.sortByOrder(resources);
    if (resources.length <= 0) {
      this.service.openDialog(`No hay recursos del tema para ordenar`).subscribe(
        () => {this.getTopics(this.moduloId);}
      )
    } else {
      this.dialog.open(DialogOrderTopicResourcesComponent,{
        data:{
          resources:resources
        },autoFocus:false
      }).afterClosed().subscribe(
        (response) => {
          if (response) {
            this.updateOrderTopicResources(response);
          }
        }
      );
    }
  }

  updateOrderTopicResources(orderedResources:any) {
    let data = {
      orderedResources:orderedResources
    };
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.updateOrderTopicResources(data).subscribe(
      (response:any) => {
        loader.close();
        this.service.openDialog(response.content.message).subscribe(
          () => {this.getTopics(this.moduloId);}
        );
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

}
