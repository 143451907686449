<div class="container-fluid">
	<div layout="row" layout-xs="column" fxLayoutAlign="space-between center" >
		<div flex="5">
			<button mat-mini-fab color="primary" focusable="false" (click)="goToCatModule()">
				<mat-icon>arrow_back</mat-icon>
			</button>
		</div>
		<div flex="70" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
			<h4>Administración de Tema</h4>
		</div>
		<ng-container *ngIf="role.isAdmin()">
			<div flex="15">
				<button mat-button class="btn-purple m-1" (click)="openDialogOrderTopics()">
					<mat-label>Ordenar Temas</mat-label>
					<mat-icon class="m-1">reorder</mat-icon>
				</button>
			</div>
			<div flex="15">
				<button mat-button class="btn-purple m-1" (click)="onClickNuevo()">
					<mat-label>Nuevo Tema</mat-label>
				</button>
			</div>
		</ng-container>
	</div> 
	<hr/>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
		<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">

			<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
				<div class="example-container mat-elevation-z8">
						<mat-table [dataSource]="dataSource" class="example-table">

							<ng-container matColumnDef="topic">
								<mat-header-cell class="m-1" *matHeaderCellDef >Tema</mat-header-cell>
								<mat-cell class="text-justify m-1" *matCellDef="let row" >
									<span class="mobile-label">Tema:</span>{{row.topic}}</mat-cell>
							</ng-container>

							<ng-container matColumnDef="description">
								<mat-header-cell class="m-1" *matHeaderCellDef >Descripción</mat-header-cell>
								<mat-cell class="text-justify m-1" *matCellDef="let row" >
									<span class="mobile-label">Descripción:</span>
									{{row.description}}</mat-cell>
							</ng-container>

							<ng-container matColumnDef="activeLabel">
								<mat-header-cell *matHeaderCellDef class="centrado">Activo</mat-header-cell>
								<mat-cell *matCellDef="let row" class="centrado">
									<span class="mobile-label">Activo:</span>
									{{row.activeLabel}}
								</mat-cell>
							</ng-container>

							<ng-container matColumnDef="simulator">
								<mat-header-cell *matHeaderCellDef class="centrado">Modelo de simulación</mat-header-cell>
								<mat-cell *matCellDef="let row" class="centrado">
									<span class="mobile-label">Modelo de simulación:</span>
									<span [style]="(row.simulator == 1)?'font-weight:bold':''">{{(row.simulator == 0)?'No':'Si'}}</span>
								</mat-cell>
							</ng-container>
				
							<ng-container matColumnDef="Borrar">
								<mat-header-cell *matHeaderCellDef  class="centrado">
									Eliminar
								</mat-header-cell>
								<mat-cell *matCellDef="let row" class="text-center"  class="centrado">
									<span class="mobile-label">Eliminar:</span>
										<button mat-mini-fab color="primary" focusable="false" aria-label="Example icon button with a delete icon" (click)="onClickDelete(row)">
												<mat-icon>delete</mat-icon>
										</button>
								</mat-cell>
							</ng-container>

              <ng-container matColumnDef="Editar">
								<mat-header-cell *matHeaderCellDef class="centrado">
									Editar
								</mat-header-cell>
								<mat-cell *matCellDef="let row" class="text-center"  class="centrado">
									<span class="mobile-label">Editar:</span>
                                    <button mat-mini-fab color="primary" focusable="false" aria-label="Example icon button with a delete icon" (click)="onClickEdit(row)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                </mat-cell>
							</ng-container>

							<ng-container matColumnDef="order">
								<mat-header-cell *matHeaderCellDef  class="centrado">
									Recursos 
								</mat-header-cell>
								<mat-cell *matCellDef="let row" class="text-center"  class="centrado">
									<span class="mobile-label">Recursos:</span>
                                    <button mat-mini-fab [matTooltip]="'Ordenar recursos del tema'" color="primary" focusable="false" (click)="openDialogOrderResources(row)">
                                        <mat-icon>reorder</mat-icon>
                                    </button>
                                </mat-cell>
							</ng-container>
							
							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
						</mat-table>
					<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>
<hr/>
