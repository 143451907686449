<div style="position: relative; height: 30px;">
  <button style="position: absolute; top: -0.5em; right: -0.5em;" mat-icon-button aria-label="close icon"
    [mat-dialog-close]="data?.infoEquipo">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="alert-green">
    <div class="fs-6">Acceso a mercado</div>
  </div>
  <div class="d-flex flex-column wh-100">
    <div class="card-market sim-card w-100 title-primary">
      <div *ngFor="let a of azuajesPurchased" class="card-product disable">
        <div [ngClass]="{'card-signal-failed':a?.order==2,'card-signal-success':a?.order!=2}"></div>
        <div class="text-item-card fs-6">{{a?.nombre}}</div>
        <div class="number-text fs-5">+{{a?.value}}</div>
        <div class="number-div fs-6">{{a?.price}}</div>
      </div>
      <div *ngFor="let av of azuajesAvailable" class="card-product" (click)="buyItemAzuaje(av)">
        <div [ngClass]="{'card-signal-failed':av?.order==2,'card-signal-success':av?.order!=2}"></div>
        <div class="text-item-card fs-6">{{av?.nombre}}</div>
        <div class="number-text fs-5">+{{av?.value}}</div>
        <div class="fx-center number-div fs-6">{{av?.price}}</div>
      </div>
    </div>
    <div class="d-flex flex-row w-100p">
      <div class="d-flex flex-column w-50">
        <div class="market-info-calc sim-card fx-center flex-column w-100">
          <div class="fx-center w-100p fs-6">Productos</div>
          <div class="d-flex flex-row fx-center w-100">
            <div class="d-flex flex-column w-100">
              <div class="fs-5 quantity text-center w-100">{{productSell}}</div>
            </div>
            <div class="d-flex flex-column">
              <button class="btn-icon" size="small" (click)="sumProduct(1)">
                <mat-icon>keyboard_arrow_up</mat-icon>
              </button>
              <button class="btn-icon" size="small" (click)="sumProduct(-1)">
                <mat-icon>keyboard_arrow_down</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row w-50 left-content">
        <div class="market-info-calc sim-card fx-center flex-column w-100">
          <div class="d-flex flex-row fx-center w-100p">
            <div class="fs-6">Precio de venta</div>
          </div>
          <div class="d-flex flex-row fx-center w-100p">
            <div class="fs-5 text-bold"> ${{price}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>