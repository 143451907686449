<div class="container-fluid">
    <div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
        <div flex="5">
            <button mat-mini-fab color="primary" focusable="false" (click)="goToCatCourse()">
                <mat-icon>arrow_back</mat-icon>
            </button>
        </div>
        <div flex="85" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
            <h4>
                Administración de Módulos
            </h4>
        </div>
        <div flex="15" *ngIf="role.isAdmin()">
            <button mat-button class="btn-purple float-right" (click)="onClickNuevo()">
                <mat-label>Nuevo Módulo</mat-label>
            </button>
        </div>
    </div>
	<hr/>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
		<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">

			<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
				<div class="example-container mat-elevation-z8">
					<mat-table #table [dataSource]="dataSource">
							<ng-container matColumnDef="module">
								<mat-header-cell class="m-1" *matHeaderCellDef> Módulo</mat-header-cell>
								<mat-cell class="text-justify m-1" *matCellDef="let element"> 
								  <span class="mobile-label">Módulo:</span>
								  {{element.module}} 
								</mat-cell>
							  </ng-container>
							  <ng-container matColumnDef="description">
								<mat-header-cell class="m-1" *matHeaderCellDef> Descripción</mat-header-cell>
								<mat-cell class="text-justify m-1" *matCellDef="let element"> 
								  <span class="mobile-label">Descripción:</span>
								  {{element.description}} 
								</mat-cell>
							  </ng-container>
							  <ng-container matColumnDef="activeLabel">
								<mat-header-cell class="centrado" *matHeaderCellDef> Activo</mat-header-cell>
								<mat-cell class="centrado" *matCellDef="let element"> 
								  <span class="mobile-label">Activo:</span>
								  {{element.activeLabel}} 
								</mat-cell>
							  </ng-container>
							  <ng-container matColumnDef="Borrar">
								<mat-header-cell *matHeaderCellDef> Eliminar</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
								  <span class="mobile-label">Eliminar:</span>
								  <button mat-mini-fab color="primary" focusable="false" (click)="onClickDelete(element)">
									<mat-icon>delete</mat-icon>
								</button>
								</mat-cell>
							  </ng-container>
							  <ng-container matColumnDef="Editar">
								<mat-header-cell *matHeaderCellDef> Editar</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
								  <span class="mobile-label">Editar:</span>
								  <button mat-mini-fab color="primary" focusable="false" aria-label="Example icon button with a delete icon" (click)="onClickEdit(element)">
									<mat-icon>edit</mat-icon>
								</button>
								</mat-cell>
							  </ng-container>
							  <ng-container matColumnDef="Temas">
								<mat-header-cell *matHeaderCellDef> Temas</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
								  <span class="mobile-label">Temas:</span>
								  <button mat-mini-fab color="primary" focusable="false" aria-label="Example icon button with a delete icon" (click)="goToTopics(element.id)">
									<mat-icon>article</mat-icon>
								</button>
								</mat-cell>
							  </ng-container>
							
							
							<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
							<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
						</mat-table>
						<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>
<hr/>
