import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ComunicationService } from 'app/comunication.service';
import { DialogCourseComponent } from 'app/shared/components/dialog-course/dialog-course.component';
import { BaseService } from 'app/views/services/base.service';
import { CatCourse } from "app/views/shared/interfaces";

@Component({
  selector: 'app-admin-courses',
  templateUrl: './admin-courses.component.html',
  styleUrls: ['./admin-courses.component.css']
})
export class AdminCoursesComponent extends BaseService implements OnInit {
  row:CatCourse;
  rolVer:boolean;
  actualIndexSelected:number = -1;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userInfo:any;
  
  constructor(
    public notificationC:ComunicationService,
    injector:Injector
  ) { 
    super(injector); 
  }

  tope=0;
  intervalo;
  async mensaje() {
   await this.notificationC.setNotifications();
    this.tope++;
  }

  intervalo_(notificationC_) {
  this.intervalo= setInterval(() => { this.mensaje(); },600000);
  }

  ngOnInit(){
    this.userInfo = this.storage.getUserInfo();
    if (this.role.isAdmin()) {
      this.displayedColumns = ['course','courseLeve','inscribed','Modules','Activiti','paymentMethods','activeLabel','Borrar','Editar','salePrice','applyPromotion'];
    }
    if (this.role.isInstructor()) {
      this.displayedColumns = ['course','courseLeve','inscribed','Modules','Activiti'];
    }
    let isLogged = (this.userInfo != null && this.userInfo != undefined);
    if (isLogged){
      this.notificationC.setNotifications();
    }
    this.getCatCourse();
  }

  getCatCourse(){
    this.loader.open();
    this.service.getCoursesAll().subscribe(
      (data:any) => {
        this.loader.close();
        switch(data.status){
          case 200:
            this.dataSource.data =  data.body.content;  
            break;
          case 500:
            this.service.openDialog(data.body.content);
            break;
          default:
            this.service.openDialog("Error al obtener la información");
            break;
        }
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.openDialog('Error con el servicio: '+http.error?.content?.message);
      }
    );
  }

  ngAfterViewInit(): void {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
  }

  public openDialogEdit() {    
    this.dialog.open(DialogCourseComponent, {
      disableClose: true,
      width: '40%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveEditCatCourse(data);
        } 
      });
  }

  desactivarCourse(){
    this.row.active =this.row.active==1?0:1; 
    this.loader.open();
    this.service.deleteCatCourse(this.row).subscribe(
      (data:any) => {
        this.loader.close();
        this.getCatCourse();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  saveEditCatCourse(data){    
    this.loader.open();
    const formdata = new FormData();
    formdata.append("data",JSON.stringify(data));
    if(data.file!=null){
      formdata.append("file",data.file.file)
    }else{
      formdata.append("file",null)
    }
    this.service.saveEditCatCourse(formdata).subscribe(
      (data:any) => {
        this.loader.close();
        this.getCatCourse();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.proccessHttpResponse(http);
      }
    );
  }

  saveCatCourse(data){
    this.loader.open();
    const formdata = new FormData();
    formdata.append("data",JSON.stringify(data));
    if(data.file!=null){
      formdata.append("file",data.file.file)
    }else{
      formdata.append("file",null)
    }
    this.service.saveCatCourse(formdata).subscribe(
      (data:any) => {
        this.loader.close();
        this.getCatCourse();
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  onClickDelete(item:any,$event : any, index:number){
    this.actualIndexSelected = index;
    this.row =item;
    let mensaje =this.row.active==1?"desactivar":"activar"
    this.openConfirm(mensaje).subscribe(
      (response) => {
        if (response) {
          this.desactivarCourse();
        } else {
          let newSource = JSON.parse(JSON.stringify(this.dataSource.data));
          newSource[this.actualIndexSelected].active = this.row.active;
          this.dataSource.data = newSource;
        }
      }
    );
  }

  onClickEdit(item:any){
    this.row =item;
    this.openDialogEdit();
  }

  public onClickNuevo() {
    let item ={
      id:0,
      course:"",
      courseLevelId:0,
      courseLeve:"",
      applyPromotion:"0",
      inscribed:12,
      active:1,
      activeLabel:"",
      salePrice:0,
      name:"",
      userId:0,
      description:"",
      imageUrl:""
    }
    this.dialog.open(DialogCourseComponent, {
      disableClose: true,
      width: '40%',
      data:item
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          this.saveCatCourse(data);
        } 
      });
  }

  goToListPupil(course_id){
    this.router.navigate(['/dashboard/cmsAlumnos',course_id]);
  }

  goToModule(course_id){
    if (this.role.isAdmin() || this.role.isInstructor()) {
      this.router.navigate(['/dashboard/cmsModule', course_id]);
    } else {
      this.openSnackBar(`La cuenta actual no tiene acceso a esta opción, favor de verificar`);
    }
  }

  goToActivity(course_id){
    this.router.navigate(['/dashboard/cmsActivity', course_id]);
  }
  
  goToTeams(course_id){
    this.router.navigate(['/dashboard/cmsTeam', course_id]);
  }

  goToPaymentMethods(courseId:number): void {
    this.router.navigate(['/dashboard/cms/courses/payments/methods', courseId]);
  }
}
