import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { AdminService } from 'app/views/services/admin.service';
import { RoleService } from 'app/views/services/role.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Echo from 'laravel-echo';
import { url } from 'ngx-custom-validators/src/app/url/validator';
import { API, ECHO_PROD, ECHO_TEST } from 'app/views/shared/api';
import { modalMultimedia } from 'app/views/shared/classes';
import { MatDialog } from '@angular/material/dialog';
import { SamRouletteComponent } from 'app/views/dialogs/sam-roulette/sam-roulette.component';
import { RouletteOfLifeComponent } from 'app/views/dialogs/roulette-of-life/roulette-of-life.component';
import { FinishDialogComponent } from 'app/shared/components/finish-dialog/finish-dialog.component';
import { OfferDemandMoulo2Component } from 'app/views/dialogs/offer-demand-moulo2/offer-demand-moulo2.component';
import { MarketAccessModule3Component } from 'app/views/dialogs/market-access-module3/market-access-module3.component';
import { AccountingExcelComponent } from 'app/views/dialogs/accounting-excel/accounting-excel.component';
import { CARDS } from '../game/game.component';

@Component({
  selector: 'app-simulator-module4',
  templateUrl: './simulator-module4.component.html',
  styleUrls: ['./simulator-module4.component.css']
})
export class SimulatorModule4Component implements OnInit {
  showMessageInit:string;
  linkMeetingZoom:string;
  rutaStorage:string = API.URL_BASE_STORAGE;
  echoEvent: any;
  users: Array<any> = [];
  usersForStore: Array<any> = [];
  colors:Array<any> = [];
  myInfoUserSession:any;
  products:Array<any>;
  productsBought:Array<any>;
  teamSelected:number;
  isSignature:boolean = false;
  game_sessions_id:number = 0;
  //Tiempo
  calendarDay: any;
  dates: Array<any> = new Array();
  seconds:number = 0;
  time:any;
  interval;
  week:number;
  step:number;
  charUrl:string;
  charName:string;
  montosXEquipo:Array<any> = [];

  pantallasVar:number;
  quantityBool:boolean;
  roulettePrize:any = "";
  //Cantidades del usuario
  leekels:number;
  inversion:number;
  gasto:number;
  ahorro:number;
  deuda:number;
  materiaPrima:number;
  montosForm:FormGroup;
  //
  totalLeekels:number = 200;
  
  mondayDays:number[]   = [5,12,19,26];  //Días que son Lunes
  tuesdayDays:number[]   = [6,13,20,27];  //Días que son Martes
  wednesdayDays:number[]   = [7,14,21,28];  //Días que son Miercoles
  thursdayDays:number[]   = [8,15,22,29];  //Días que son Jueves
  fridayDays:number[]   = [2,9,16,23,30];//Días que son Viernes
  saturdayDays:number[] = [3,10,17,24];  //Días que son Sábado
  sundayDays:number[] = [4,11,18,25];  //Días que son Domingo

  //RULETA
  options:string[] = ["!¿Incobrable?¡", "Pagar sólo la \n mitad el Jueves", "Pagar el 100% a fin de mes", "Pagar la mitad a fin de mes", "Pagar el 100% el Jueves", "Pagar el Jueves la mitas, y la otra mitad al final del mes"];

  startAngle:number = 0;
  spinTimeout: ReturnType<typeof setTimeout> = null;

  spinArcStart:number = 10;
  spinTime:number = 0;
  spinTimeTotal:number = 0;

  spinAngleStart:number = 0;
  arc:number = Math.PI / (this.options.length / 2);
  ctx;

  constructor(public services: AdminService, public role: RoleService, private router: Router, private loader: AppLoaderService, public dialog: MatDialog) {
    // Configuracion general de pusher
    this.echoEvent = new Echo((API.PROD)?ECHO_PROD:ECHO_TEST);
    this.inversion = 0;
    this.gasto = 0;
    this.ahorro = 0;
    this.deuda = 380;

    this.montosForm = new FormGroup({
      inversion: new FormControl('0',[Validators.required]),
      gasto: new FormControl('0',[Validators.required]),
      ahorro: new FormControl('0',[Validators.required]),
      team: new FormControl('')
    });

    
  }

  ngOnInit(): void {
    this.showMessageInit = localStorage.getItem('tutorialGameIsDisplayed');
    //agregar el remove solo testing
    /* localStorage.removeItem('roulettePrize');
    localStorage.removeItem('currentCard'); */
    localStorage.removeItem('isSignature');
    let isSignatureStore = localStorage.getItem('isSignature');
    this.isSignature = Number(isSignatureStore)==1 ? true : false;
    this.step = this.isSignature ? 2 : 0;
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.roulettePrize = JSON.parse(localStorage.getItem('roulettePrize') || '{}');
    //this.currentCard = JSON.parse(localStorage.getItem('currentCard') || '{}');
    this.myInfoUserSession = {
      email: userInfo.email,
      game_sessions_id: 0,
      id: userInfo.id,
      image_url: userInfo.imagenURL,
      last_name: userInfo.lastName​​,
      name: userInfo.name,
      team: 0
    };
    this.linkMeetingZoom = localStorage.getItem('linkMeetingZoom');
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    
    let courseIdAcceso = JSON.parse(localStorage.getItem('courseIdAcceso'));
    if(!codigoAcceso && !courseIdAcceso){
      this.router.navigate(['/dashboard/home']);
      return;
    }

    // this.step = 0;
    // ejecutar canal de escucha
    this.updatedDayEvent();
    this.startTimer();
    this.getCalendarDay();

    this.leekels = 0;
    this.inversion = 0;
    this.ahorro = 0;
    this.gasto = 0;
    this.materiaPrima = 0;
    this.deuda = 250;
    this.charUrl = "assets/images/game/personajes/14-Susana-Sensata.jpg";
    this.charName = "Susana Sensata";
    var equipos = localStorage.getItem('arrayMontosXEquipo');
    if(equipos != null){
      this.montosXEquipo = new Array();
      this.montosXEquipo = JSON.parse(equipos);
    }
    this.drawRouletteWheel();
  }

  ngAfterViewInit(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialGameIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialGameIsDisplayed','1');
      setTimeout(this.services.showTutorialGameClient, 100);
    }
  }

  ngOnDestroy(){
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.echoEvent.leaveChannel('change-calendar-day-'+codigoAcceso);
  }

  updatedDayEvent() {
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso').toLocaleLowerCase();
    this.echoEvent.channel('change-calendar-day-'+codigoAcceso)
    .listen('CalendarDaysEvent', (response) => {
      // datos ejecutado 
      if (response.data && response.data.length > 0) {
        this.calendarDay = response.data[0];
        this.checkDay(this.calendarDay?.id);
        this.disableRouletteButton = false;
        this.showStopButton = true;
        if(this.calendarDay?.id == 30){
          this.showFinishedDialog();
        }
      }
      this.validateRouletteSam();
    }).listen('GameSessionUserEvent', (response) => {
      // datos ejecutado
      if(Array.isArray(response.data)){
        this.montosXEquipo = response.data;
      }else{
        this.montosXEquipo = [{...response.data}];
      }
      let data = response.data;
      if (data.updateAmount){
        if(data.team!=null){
          let isTeamInTeamsAmount = this.montosXEquipo.some((t)=>t.team==data.team);
          if(!isTeamInTeamsAmount){
            this.montosXEquipo.push(data);
          }
          localStorage.setItem('arrayMontosXEquipo',JSON.stringify(this.montosXEquipo));
        }
        this.setupMontos(data);
      } else {
        this.filterUserByTeam(response.data);
      }
      this.validateRouletteSam();
    }).listen('ProductSaleEvent', (response) => {
      // datos ejecutado
      if(response.data.isUpdate){
        this.products = response.data.products;
        return;
      }

      if(this.role.isClient()){
        this.products.map((p)=>{
          if(p.id==response.data.id && this.myInfoUserSession.team==response.data.team){
            p.isSelected = 1;
          }else{
            p.isSelected = 0;
          }
        });
      }
      this.validateRouletteSam();
    }).listen('RouletteEvent', (response) => {
      // datos ejecutado
      if(this.myInfoUserSession.team==response.data.team){
        if(response.data.isSignature){
          this.isSignature = true;
          localStorage.setItem('isSignature','1');
          this.step = 2;
        }else if(this.week == response.data.week){
          this.roulettePrize = response.data;
          this.montosXEquipo = this.montosXEquipo.map((amount)=>{
            if(this.week == 3){
              amount.roulette_sam_1 = this.roulettePrize.roulettePrize;
            }
            if(this.week == 4){
              amount.roulette_sam_2 = this.roulettePrize.roulettePrize;
            }
            if(this.week == 5){
              amount.roulette_sam_3 = this.roulettePrize.roulettePrize;
            }
            return amount;
          });
          localStorage.setItem('arrayMontosXEquipo',JSON.stringify(this.montosXEquipo));
          localStorage.setItem('roulettePrize',JSON.stringify(response.data));
        }
      }
      this.validateRouletteSam();
    }).error((error) => {});

  }

  myTeam:any = undefined;
  filterUserByTeam(user:Array<any>){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.myInfoUserSession = [...user].find((user)=>user.id == userInfo.id );
    if(this.myInfoUserSession){
      if(this.myInfoUserSession.leader){
        this.montosForm.get("inversion").enable();
        this.montosForm.get("gasto").enable();
        this.montosForm.get("ahorro").enable();
      }else{
        this.montosForm.get("inversion").disable();
        this.montosForm.get("gasto").disable();
        this.montosForm.get("ahorro").disable();
      }
      this.users = [...user].filter((user)=>user.team == this.myInfoUserSession.team && user.id != this.myInfoUserSession.id);
      this.myTeam = this.colors.find((t) => t.team === this.myInfoUserSession.team);

      let equipo = localStorage.getItem("infoEquipo");
      if( equipo == null ){
        let data = this.chooseMontoByTeamRol();
        this.inversion = data[0];
        this.gasto = data[1];
        this.ahorro = data[2];
        this.leekels = this.inversion + this.gasto + this.ahorro;
    
        this.montosForm.get("inversion").setValue(this.inversion);
        this.montosForm.get("gasto").setValue(this.gasto);
        this.montosForm.get("ahorro").setValue(this.ahorro);
      }else{
        let data = JSON.parse(equipo);

        this.inversion = data.inversion;
        this.gasto = data.gasto;
        this.ahorro =  data.ahorro;
        this.leekels = this.inversion + this.gasto + this.ahorro;
    
        this.montosForm.get("inversion").setValue(this.inversion);
        this.montosForm.get("gasto").setValue(this.gasto);
        this.montosForm.get("ahorro").setValue(this.ahorro);
      }
      
    }else{
      this.users = [];
    }
  }

  public checkTotales(){
    return (Number(this.montosForm.get('inversion').value) + Number(this.montosForm.get('gasto').value) + Number(this.montosForm.get('ahorro').value)) != this.totalLeekels;
  }

  setMontos(data:any){
    this.montosForm.get('inversion').setValue(data?.inversion);
    this.montosForm.get('gasto').setValue(data?.gasto);
    this.montosForm.get('ahorro').setValue(data?.ahorro);
    this.montosForm.get('team').setValue(data?.team);
    this.inversion = data?.inversion;
    this.gasto = data?.gasto;
    this.ahorro = data?.ahorro;
    this.totalLeekels = data?.totalLeekels;
    this.materiaPrima = data?.materiaPrima;
    this.deuda = data?.deuda;
    localStorage.setItem("infoEquipo", JSON.stringify(data) );
  }

  public setupMontos(data){
    //Validamos si solo se va actualizar a 1 equipo
    if (data.onlyOne) {
      //Actualizar solo si es el equipo actual
      if (this.myTeam && this.myTeam.team == data.team) {
        this.setMontos(data);
      }
    } else {
      //Si se tiene que actualizar a todos
      this.setMontos(data);
    }
  }

  nextStep(){    
    if(!this.myInfoUserSession.leader && this.step == 0){ 
      this.step = (this.isSignature) ? 2 : 1;
    }  else if(this.step == 0){ 
      this.step =  1;
    }
    else if(this.step == 1){
      this.step = 2;
    }
    else if(this.myInfoUserSession.leader && this.step == 1){
      this.changeFirmaContrato();
      this.step++;
    }else if(this.myInfoUserSession.leader && this.step == 2){
      // this.changeFirmaContrato();
      this.step++;
    }else if(this.step == 3){
      this.changeCharVideo('assets/images/game/personajes/14-Susana-Sensata.jpg', 1);
      this.step=4;
    }else if(this.step < 5){
      this.step++;
    }
  }

  previousStep(){
    if(this.step == 1){
      this.step = 0;
      return;
    }

    if(this.step == 3){
      if(!this.myInfoUserSession.leader && this.charNumb==1){
        this.step = (this.isSignature) ? 2 : 1;
      }else if(this.myInfoUserSession.leader && this.charNumb==1){
        this.step--;
      }
    }else if(this.step>3){
      this.step--;
    }
  }

  charNumb:number = 1;
  changeCharVideo(url, char){        
    this.charName = "Susana Sensata";
    this.charNumb = char;
    this.charUrl = url;
    
  }

  getCalendarDay() {
    this.loader.open('Obteniendo informacion del juego..');
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    let courseIdAcceso = JSON.parse(localStorage.getItem('courseIdAcceso'));
    this.services.getCalendarDay({codigo:codigoAcceso,courseId:courseIdAcceso}).subscribe(
      (response: any) => {
        this.loader.close();
        if (response.success) {
          this.calendarDay = response.data.day;
          this.colors = response.data.allTeams.filter((team)=>team.team_type_id == 2 || team.team_type_id == 3);
          if(this.calendarDay?.game_sessions_id){
            this.game_sessions_id = this.calendarDay?.game_sessions_id;
          }
          if(this.calendarDay.id > 1){
            this.step = 4
          }
          this.products = response.data.products;
          this.usersForStore = response.data.users;
          this.filterUserByTeam(response.data.users);
          this.checkDay(this.calendarDay?.id);
          let teanAmount = response.data.amountTeams.filter(amount=>amount.team == this.myInfoUserSession.team);
          this.montosXEquipo = response.data.amountTeams;
          localStorage.setItem('arrayMontosXEquipo', JSON.stringify(this.montosXEquipo));
          if(this.role.isClient() && teanAmount.length>0){
            teanAmount[0].isUpdateMontos = false;
            teanAmount[0].onlyOne = true;
            teanAmount[0].updateAmount = true;
            this.setMontos(teanAmount[0]);
          }
        }
        this.validateRouletteSam();
      },
      (error)=>{
        this.loader.close();
      }
    )
  }

  selectedProduct(product:any){
    if(Number(this.teamSelected)>0){
      this.products.map((p)=>{
        if(p.id==product.id){
          p.isSelected = 1;
        }else{
          p.isSelected = 0;
        }
      });
      //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
      let codigoAcceso = localStorage.getItem('codigoAcceso');
      this.services.changeProductSelected({...product, codigo: codigoAcceso, team: this.teamSelected}).subscribe(
        (result)=>{},
        (result:HttpErrorResponse)=>{
          this.products.map((p)=>{
            p.isSelected = 0;
          });
          this.services.openDialog('No se pudo actualizar productos en alumnos');
        },
      )
    }else{
      this.services.openDialog('Seleccione un equipo.');
    }
  }

  buyProduct(){
    let product = this.products.find((p)=>p.isSelected == 1);
    if(product && this.teamSelected>0){
      this.loader.open('Comprando producto...');
      let codigoAcceso = localStorage.getItem('codigoAcceso');
      this.services.buyProductSelected({...product, codigo: codigoAcceso, game_sessions_id: this.calendarDay.game_sessions_id }).subscribe(
        (response:any)=>{
          this.loader.close();
          this.services.openDialog(response.message);
        },
        (error)=>{
          this.loader.close();
        },
      )
    }else{

    }
  }

  changeColorUsers(){
    this.loader.open('Actualizando equipos..');
    let usersSave = this.users.filter(element => element.team != null);
    if(usersSave.length>0){
      let usersToSave = [...usersSave].map((user) => { return { game_sessions_id: user.game_sessions_id, users_id: user.id, team: user.team }});
      this.services.openConfirm(`¿Seguro(a) que desea guardar los cambios?`)
      .then(
        (response) => {
          if (response) {
            //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
            let codigoAcceso = localStorage.getItem('codigoAcceso');
            this.services.updateColorUsersGameSessions({codigo: codigoAcceso, users: usersToSave}).subscribe(
              (response:any)=>{
                this.loader.close();
                this.services.openDialog(response.message)
              },
              (error:HttpErrorResponse)=>{
                this.loader.close();
                this.services.openDialog(`Error al: aceptar ${error.message}`)
              }
            )
          }
        }
      )
      .catch(
        (error:HttpErrorResponse) => {
          this.services.openDialog(`Error al: aceptar ${error.message}`)
        }
      ); 
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
        this.seconds++;
        this.time = new Date(this.seconds * 1000).toISOString().substr(11, 8);
    },1000)
  }

  changeDay() {
    if (!this.calendarDay) return;
    //let codigoAcceso = JSON.parse(localStorage.getItem('codigoAcceso'));
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    let courseIdAcceso = JSON.parse(localStorage.getItem('courseIdAcceso'));
    this.services.changeDay({...this.calendarDay,codigo:codigoAcceso,courseId:courseIdAcceso}).subscribe(
      (response: any) => {
        if (response.success) {
          this.calendarDay = (response.data && response.data.length > 0) ? response.data[0] : this.calendarDay;
          this.checkDay(this.calendarDay?.id);
        }
      }
    )
  }

  public generateDate() {
    for (let index: number = 1; index <= 31; index++) {
      this.dates.push({
        dia: index,
        diaMes: this.getDiaDeLaSemana(index)
      });
    }
  }

  public getDiaDeLaSemana(diaMes: number) {
    var arrayDias = ["JUEVES", "VIERNES", "SABADO", "DOMINGO", "LUNES", "MARTES", "MIERCOLES"];
    var index: any = -1;
    if (diaMes > 1 && diaMes < 7) {
      return arrayDias[diaMes - 1];
    } else if (diaMes) {
    }
    //var index:any =  diaMes <=7 ? diaMes - 1 :  (~~(diaMes/7) + (~~(diaMes%7)) ) -1;
    return arrayDias[(~~(diaMes / 7) + (~~(diaMes % 7))) - 1];
  }

  public checkDay(dayOfMonth: any){
    let day = Number(dayOfMonth)
    if (day == 1 ) {
      let data = this.chooseMontoByTeamRol();
      if(data[0] || data[1] || data[2]){
        this.inversion = data[0];
        this.gasto = data[1];
        this.ahorro = data[2];
      }else{
        this.leekels = 300;
        this.inversion = 0;
        this.gasto = 0;
        this.ahorro = 0;
        this.deuda = 0;
      }
    }

    if(day >= 1 && day <= 4){
      this.week = 1;
    }
    else if(day >= 5 && day <= 11){
      this.week = 2;
    }
    else if(day >= 12 && day <= 18){
      this.week = 3;
    }
    else if(day >= 19 && day <= 25){
      this.week = 4;
    }
    else if(day >= 26 && day <= 30){
      this.week = 5;
    }
    //Validacion para habilitar la seleccion de tarjetas de la vida
    if (dayOfMonth == 20 || dayOfMonth == 27){
      this.showCards = false;
    }
    //Validación para habilitar la sección de ruleta
    if (dayOfMonth > 11) {
      this.initRoullete();
    }
  }

  dayName(id){
    if(id == 1 || id == 8 || id == 15 || id == 22 || id == 29){
      return "Jueves"
    }
    if(id == 2 || id == 9 || id == 16 || id == 23 || id == 30){
      return "Viernes"
    }
    if(id == 3 || id == 10 || id == 17 || id == 24){
      return "Sábado"
    }
    if(id == 4 || id == 11 || id == 18 || id == 25){
      return "Domingo"
    }
    if(id == 5 || id == 12 || id == 19 || id == 26){
      return "Lunes";
    }
    if(id == 6 || id == 13 || id == 20 || id == 27){
      return "Martes";
    }
    if(id == 7 || id == 14 || id == 21 || id == 28){
      return "Miércoles"
    }
  }


  public OnGuardarCambioMontos(message:string = "Actualizando montos..."){
    
    this.loader.open(message);
    let data_ = this.chooseMontoByTeamRol();
    this.inversion = data_[0];
    this.gasto = data_[1];
    this.ahorro = data_[2];
    let data = this.montosForm.value;
    
    let session:any = this.getSession();
    if (session) {
      data.codigo = session.codigoAcceso;
      data.team   = session.team;
      data.updateAmount = true;
      data.totalLeekels = Number(data.inversion)+Number(data.gasto)+Number(data.ahorro);
      this.totalLeekels = data.totalLeekels;
      data.onlyOne = true;
      data.deuda = this.deuda;
      data.materiaPrima = this.materiaPrima;
      data.card = this.currentCard;
      this.services.updateMontos(data).toPromise()
      .then((response : any ) => {
        this.loader.close();
        localStorage.setItem('infoEquipo',JSON.stringify(data));
        if(this.showMessageInit=='1'){
          this.services.openDialog(response.message);
        }
      }).catch((error : any ) => {
        this.loader.close();
        
      });
    }else{
      this.loader.close();
    }
  }
  public chooseMontoByTeamRol():Array<any>{
    let data = this.montosForm.value;
    let inversion = data?.inversion;
    let gasto = data?.gasto;
    let ahorro = data?.ahorro;
    let montos:Array<any> = [inversion, gasto, ahorro];

    return montos;
  }

  getSession():any{
    try {
      let codigoAcceso = JSON.parse( JSON.stringify(localStorage.getItem('codigoAcceso')) );
      let courseIdAcceso = JSON.parse( JSON.stringify(localStorage.getItem('courseIdAcceso')) );
      let userInfo:any = JSON.parse(JSON.stringify(localStorage.getItem('userInfo')) );
      let team:number  = (this.myTeam)?this.myTeam.team:null;
      return {codigoAcceso,courseIdAcceso,userId:userInfo.id,team:team};
    } catch (error) {  
      return undefined;
    }
  }

  leekelsImage(){
    let url = "assets/images/game/contrato/contrato-sin-firma-300.png";
    if(this.step == 1){
      url = "assets/images/game/contrato/contrato-sin-firma-300.png";
      if(this.myTeam?.team_type_id == 3){
        url = "assets/images/game/contrato/contrato-sin-firma-1200.png";
      }else{
        url = "assets/images/game/contrato/contrato-sin-firma-300.png";
      }
      // url = "assets/images/game/contrato/contrato sin firma.png"
    }
    else{
      if(this.myTeam?.team_type_id == 3){
        url = "assets/images/game/contrato/contrato---firmado-1200.png";
      }else{
        url = "assets/images/game/contrato/contrato---firmado-300.png";
      }
      // url = "assets/images/game/contrato/contrato - firmado.png"
    }
    return url;
  }

  dayIn(day:number,daysArray:number[]){
    let dayExists:number = daysArray.find((d) => d === day);
    return (dayExists)?true:false;
  }

  confirmUpdateLeekelsByTeam(team:any,investment:any,spend:any,saving:any){
    this.services.openConfirm(`¿Seguro(a) que desea actualizar los leekels al equipo ${team?.team}?`)
    .then(
      (response) => {
        if (response){
          team.inversion = Number(investment);
          team.gasto     = Number(spend);
          team.ahorro    = Number(saving);
          team.updateAmount = true;
          team.onlyOne      = true; //Bandera para saber si actualiza a todos o sólo a un equipo
          this.updateLeekelsByTeam(team);
        }
      }
    )
    .catch(
      (error) => {
        this.services.openDialog(`Error al actualizar los leekels del equipo ${team?.team}`);
      }
    )
  }

  public updateLeekelsByTeam(team:any){
    this.loader.open(`Actualizando montos al equipo: ${team?.team}`);
    this.services.updateMontos(team).toPromise()
    .then((response : any ) => {
      this.loader.close();
      this.services.openDialog(response.message);
    }).catch((error : any ) => {
      this.loader.close();
      this.services.openDialog(`Error al actualizar los leekels al equipo ${team?.team}`);
    });
  }

  byte2Hex(n) {
    var nybHexString = "0123456789ABCDEF";
    return String(nybHexString.substr((n >> 4) & 0x0F,1)) + nybHexString.substr(n & 0x0F,1);
  }

  RGB2Color(r,g,b) {
    return '#' + this.byte2Hex(r) + this.byte2Hex(g) + this.byte2Hex(b);
  }

  getColor(item, maxitem) {
    var phase = 0;
    var center = 128;
    var width = 127;
    var frequency = Math.PI*2/maxitem;
    
    var red   = Math.sin(frequency*item+2+phase) * width + center;
    let green = Math.sin(frequency*item+0+phase) * width + center;
    let blue  = Math.sin(frequency*item+4+phase) * width + center;
    
    return this.RGB2Color(red, green, blue);
  }

  drawRouletteWheel() {
    var canvas = <HTMLCanvasElement>document.getElementById("canvas");
    if (canvas && canvas.getContext) {
      var outsideRadius = 200;
      var textRadius = 160;
      var insideRadius = 125;
  
      this.ctx = canvas.getContext("2d");
      this.ctx.clearRect(0,0,500,500);
  
      this.ctx.strokeStyle = "black";
      this.ctx.lineWidth = 2;
  
      this.ctx.font = 'bold 12px Helvetica, Arial';
  
      for(var i = 0; i < this.options.length; i++) {
        var angle = this.startAngle + i * this.arc;
        //ctx.fillStyle = colors[i];
        this.ctx.fillStyle = this.getColor(i, this.options.length);
  
        this.ctx.beginPath();
        this.ctx.arc(250, 250, outsideRadius, angle, angle + this.arc, false);
        this.ctx.arc(250, 250, insideRadius, angle + this.arc, angle, true);
        this.ctx.stroke();
        this.ctx.fill();
  
        this.ctx.save();
        this.ctx.shadowOffsetX = -1;
        this.ctx.shadowOffsetY = -1;
        this.ctx.shadowBlur    = 0;
        this.ctx.shadowColor   = "rgb(220,220,220)";
        this.ctx.fillStyle = "black";
        this.ctx.translate(250 + Math.cos(angle + this.arc / 2) * textRadius, 
                      250 + Math.sin(angle + this.arc / 2) * textRadius);
                      this.ctx.rotate(angle + this.arc / 2 + Math.PI / 2);
        var text = this.options[i];
        this.ctx.fillText(text, -this.ctx.measureText(text).width / 2, 0);
        this.ctx.restore();
      } 
  
      //Arrow
      this.ctx.fillStyle = "black";
      this.ctx.beginPath();
      this.ctx.moveTo(250 - 4, 250 - (outsideRadius + 5));
      this.ctx.lineTo(250 + 4, 250 - (outsideRadius + 5));
      this.ctx.lineTo(250 + 4, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 + 9, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 + 0, 250 - (outsideRadius - 13));
      this.ctx.lineTo(250 - 9, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 - 4, 250 - (outsideRadius - 5));
      this.ctx.lineTo(250 - 4, 250 - (outsideRadius + 5));
      this.ctx.fill();
    }
  }

  spin() {
    let message:string = "Sam el Mayorista presenta los siguientes riesgos: <br>-Incobrable <br>-Pagar sólo la mitad el Jueves <br>-Pagar el 100% a fin de mes <br>-Pagar la mitad a fin de mes <br>-Pagar el 100% el Jueves <br>-Pagar el jueves la mitad y la otra mitad a fin de mes <br><br> ¿Estás seguro que quieres girar la ruleta?";
    this.services.openConfirm(message)
    .then((response) => {
      if(response){

        this.spinAngleStart = Math.random() * 10 + 10;
        this.spinTime = 0;
        this.spinTimeTotal = Math.random() * 3 + 4 * 1000;
        this.rotateWheel();
      }
    }).catch((error: any) => {
      this.services.openDialog("Ocurrió un error al mostrar el mensaje.");
    });
  }

  rotateWheel() {
    this.spinTime = this.spinTime + 30;
    if(this.spinTime >= this.spinTimeTotal) {
      this.stopRotateWheel();
      return;
    }
    var spinAngle = this.spinAngleStart - this.easeOut(this.spinTime, 0, this.spinAngleStart, this.spinTimeTotal);
    this.startAngle += (spinAngle * Math.PI / 180);
    this.drawRouletteWheel();
    this.spinTimeout = setTimeout(() => {this.rotateWheel()}, 30);
  }

  stopRotateWheel() {
    clearTimeout(this.spinTimeout);
    var degrees = this.startAngle * 180 / Math.PI + 90;
    var arcd = this.arc * 180 / Math.PI;
    var index = Math.floor((360 - degrees % 360) / arcd);
    this.ctx.save();
    this.ctx.font = 'bold 30px Helvetica, Arial';
    var text = this.options[index];
    this.ctx.fillText(text, 250 - this.ctx.measureText(text).width / 2, 250 + 10);
    this.ctx.restore();
    this.changeRoulette(text);
  }

  changeRoulette(roulettePrize:string){
    if(!this.myInfoUserSession?.team) return;
    
    let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.services.changeRoulette({codigo: codigoAcceso, team: this.myInfoUserSession.team, roulettePrize: roulettePrize, week: this.week, isSignature: false }).subscribe(
      (result)=>{
        for (let index = 0; index < this.montosXEquipo.length; index++) {
          const amount = this.montosXEquipo[index];
          if(amount.team == this.myInfoUserSession.team){
            if(this.week == 3 && roulettePrize){
              amount.roulette_sam_1 = roulettePrize;
            }
            if(this.week == 4 && roulettePrize){
              amount.roulette_sam_2 = roulettePrize;
            }
            if(this.week == 5 && roulettePrize){
              amount.roulette_sam_3 = roulettePrize;
            }
          }
        }
        this.validateRouletteSam();
        this.services.openDialog(roulettePrize,'Sam el Mayorista')
      },
      (result:HttpErrorResponse)=>{
        this.products.map((p)=>{
          p.isSelected = 0;
        });
        this.services.openDialog('No se pudo actualizar productos en alumnos');
        this.initRoullete();
      },
    )
  }

  easeOut(t, b, c, d) {
    var ts = (t/=d)*t;
    var tc = ts*t;
    return b+c*(tc + -3*ts + 3*t);
  }

  //Tarjetas de la vida
  showCards:boolean = false;
  currentCard:string;
  showStopButton:boolean = true;
  setShowCards(){
    this.currentCard = '';
    if (!this.showCards) {
      // this.showCards = true;
      this.dialog.open(RouletteOfLifeComponent,{
        disableClose: true,
        data: this.montosXEquipo.find(t=>t.team==this.myTeam.team)
      }).afterClosed().subscribe(
        (response)=>{
          if(response){
            this.currentCard = response;
            this.showCards = true;
            this.showStopButton = false;
            this.sendCardToTeam(this.currentCard);
          }else{
            this.showCards = true;
            this.showStopButton = false;
          }
        }
      );
    }
  }
  
  sendCardToTeam(card:any){
    let session:any = this.getSession();
    if (session) {
      let data:any = {};
      data.codigo = session.codigoAcceso;
      data.team   = session.team;
      data.inversion = this.inversion;
      data.gasto = this.gasto;
      data.ahorro = this.ahorro;
      data.updateAmount = true;
      if(Number(this.inversion)+Number(this.gasto)+Number(this.ahorro)>0){
        data.totalLeekels = Number(this.inversion)+Number(this.gasto)+Number(this.ahorro);
      }else{
        data.totalLeekels = this.totalLeekels;
      }
      data.onlyOne = true;
      data.deuda = this.deuda;
      data.materiaPrima = this.materiaPrima;
      data.card = card;
      this.loader.open('Actualizando tarjeta ...');
      this.services.updateMontos(data).toPromise()
      .then((response : any ) => {
        this.loader.close();
        this.services.openDialog(card.text,'Tarjeta de la Vida');
        localStorage.setItem('currentCard',JSON.stringify(card));
      }).catch((error : any ) => {
        this.loader.close();
        
      });
    }
  }

  openCalendarInfoDialog(tipo:number){
    let data:modalMultimedia = new modalMultimedia('','assets/images/game/01 Cartel Ciclo de Negocios.jpg',1,'');
    // seleccion de tipo 1 = imagen, 2 = video
    if(this.calendarDay && tipo==2){
      switch (this.calendarDay.id) {
        case 8:
          data = new modalMultimedia(`Semana ${this.week} día Jueves`,'assets/videos/modulo4/jueves_dia_8.mp4',2,'');
          break;
        case 15:
          data = new modalMultimedia(`Semana ${this.week} día Jueves`,'assets/videos/modulo4/jueves_dia_15.mp4',2,'');
          break;
        case 22:
          data = new modalMultimedia(`Semana ${this.week} día Jueves`,'assets/videos/modulo4/jueves_dia_22.mp4',2,'');
          break;
        case 30:
          data = new modalMultimedia(`Semana ${this.week} día Jueves`,'assets/videos/modulo4/conclusiones.mp4',2,'');
          break;
        default:
          break;
      }
    }
    this.services.openCalendarInfoDialog(data)
    .then((response) => {})
    .catch((error)=> {});
  }

  //RULETA NUEVA
  roulette:any;
  disableRouletteButton:boolean = false;
  initRoullete(){
    this.roulette = ROULETTE[0];
    /*
    if (this.roulettePrize?.week && this.roulettePrize?.week==this.week) {
      this.disableRouletteButton = true;
    } else { 
      this.disableRouletteButton = false;
    } */
  }

  spinRoulette(){
    if (!this.disableRouletteButton) {
      this.disableRouletteButton = true;
      this.dialog.open(SamRouletteComponent,{
        disableClose: true
      }).afterClosed().subscribe(
        (response)=>{
          if(response){
            this.roulette = response;
            this.changeRoulette(this.roulette.text);
          }else{
            this.disableRouletteButton = false;
          }
        }
      );
      /* this.roulette = ROULETTE[1];
      this.disableRouletteButton = true;
      setTimeout(() => {
        let index:number = this.getRandomNumber(2,8);
        this.roulette = ROULETTE[index];
        this.changeRoulette(this.roulette.text);
      },3000); */
    }
  }

  getRandomNumber(min:number,max:number):number{
    return Math.floor(Math.random() * (max - min) + min);
  }

  // uso el evento de la ruleta para la firma del contrato y no crear un nuevo envento
  changeFirmaContrato(){
    if(!this.myInfoUserSession.leader) return;

    let codigoAcceso = localStorage.getItem('codigoAcceso');
    this.services.changeRoulette({codigo: codigoAcceso, team: this.myInfoUserSession.team, roulettePrize: '', week: this.week, isSignature: true }).subscribe(
      (result)=>{
        localStorage.setItem('isSignature','1');
      },
      (result:HttpErrorResponse)=>{},
      ()=>{
        localStorage.setItem('isSignature','1');
      }
    )
  }

  public showFinishedDialog(){
    this.dialog.closeAll();
    this.dialog.open(FinishDialogComponent,{
      disableClose: true,
      width: '85%',
      height: '60%',
      minWidth: '40%',
      minHeight: '25%'
    }).afterClosed();
  }

  public showProductsBoughtDialog(){
    
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let myInfoUserSession = [...this.usersForStore].find((user)=>user.id == userInfo.id );
    if( typeof myInfoUserSession == 'undefined'){
      return;
    }

    this.loader.open("Buscando productos...");    
    this.services.getProductsXTeam({game_sessions_id: this.game_sessions_id, team_id: myInfoUserSession.team }).toPromise()
    .then((response : any) => {
      this.loader.close();
      if(response != null){
        if (response.success == true) {
          this.productsBought = response.data;
          this.services.openProductsBoughtByTeamDialog({products: this.productsBought, team: myInfoUserSession.team});
        } else {
          this.productsBought = new Array();
        }
      }else{
        this.productsBought = new Array();
      }
    }).catch((error) => {
      this.loader.close();
    });
  }

  public openDialogOfferAndDemand(){
    this.dialog.open(OfferDemandMoulo2Component,{
      width: '550px',
      disableClose: false,
      panelClass: 'modal-simulator',
      data: { day: this.calendarDay.id, week: this.week }
    });
  }

  public openDialogAccessMarket(){
    let infoEquipo = localStorage.getItem('infoEquipo') == null ? {} : JSON.parse( localStorage.getItem('infoEquipo'));
    this.dialog.open(MarketAccessModule3Component,{
      width: '550px',
      disableClose: false,
      panelClass: 'modal-simulator',
      autoFocus: false,
      data: { team: this.myInfoUserSession.team, game_sessions_id: this.game_sessions_id, isLeader: this.myInfoUserSession.leader,infoEquipo: infoEquipo }
    }).afterClosed().toPromise()
    .then((response : any ) => {
      if(response != null ){
        this.inversion = response.inversion;
        this.gasto = response.gasto;
        this.ahorro = response.ahorro;
        this.totalLeekels = response.totalLeekels;
        this.montosForm.get("inversion").setValue(this.inversion);
        this.montosForm.get("gasto").setValue(this.gasto);
        this.montosForm.get("ahorro").setValue(this.ahorro);
      }
    }).catch((error : any) => {

    });
  }
  
  public openAccountingExcelDialog(){
    this.dialog.open(AccountingExcelComponent,{
      width: '90%',
      height: '85%',
      disableClose: false,
      data: { week: this.week }
    });
  }

  validateRouletteSam(){
    // this.disableRouletteButton = false;
    for (let index = 0; index < this.montosXEquipo?.length; index++) {
      const amount = this.montosXEquipo[index];
      if(amount.team == this.myInfoUserSession.team){
        if(this.week == 3 && amount.roulette_sam_1){
          this.roulettePrize.week = 3;
          this.roulettePrize.roulettePrize = amount.roulette_sam_1;
          this.disableRouletteButton = true;
        }
        if(this.week == 4 && amount.roulette_sam_2){
          this.roulettePrize.week = 4;
          this.roulettePrize.roulettePrize = amount.roulette_sam_2;
          this.disableRouletteButton = true;
        }
        if(this.week == 5 && amount.roulette_sam_3){
          this.roulettePrize.week = 5;
          this.roulettePrize.roulettePrize = amount.roulette_sam_3;
          this.disableRouletteButton = true;
        }
      }
    }
  }
}

export const ROULETTE:any[] = [
  {id:0,image:'assets/images/game/ruleta/INICIO.png',text:undefined},
  {id:1,image:'assets/images/game/ruleta/RUEDA_LOOP.gif',text:'Girando Ruleta'},
  {id:2,image:'assets/images/game/ruleta/RUEDA_1.png',text:'PAGAR EL 100% EL JUEVES'},
  {id:3,image:'assets/images/game/ruleta/RUEDA_2.png',text:'PAGAR SÓLO LA MITAD A FIN DE MES'},
  {id:4,image:'assets/images/game/ruleta/RUEDA_3.png',text:'PAGAR EL 100% A FIN DE MES'},
  {id:5,image:'assets/images/game/ruleta/RUEDA_4.png',text:'PAGAR SÓLO LA MITAD EL JUEVES'},
  {id:6,image:'assets/images/game/ruleta/RUEDA_5.png',text:'¿INCOBRABLE!'},
  {id:7,image:'assets/images/game/ruleta/RUEDA_6.png',text:'PAGAR JUEVES LA MITAD, Y LA MITAD A FIN DE MES'}
];