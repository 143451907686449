<app-header-top-sessions fxFlex *ngIf="!activo"></app-header-top-sessions>
<app-header-top fxFlex *ngIf="activo"></app-header-top>
<div [style.margin-top]="getMargin()" style="overflow: hidden;width: 100%;">
	<div class="page-wrapper">

			<div class="stricky-header stricked-menu main-menu">
					<div class="sticky-header__content">

					</div><!-- /.sticky-header__content -->
			</div><!-- /.stricky-header -->


			<section class="main-slider main-slider-one">
					<div class="swiper-container thm-swiper__slider" data-swiper-options='{"slidesPerView": 1, "loop": false, "effect": "fade", "pagination": {
			"el": "#main-slider-pagination",
			"type": "bullets",
			"clickable": true
			},
			"navigation": {
			"nextEl": "#main-slider__swiper-button-next",
			"prevEl": "#main-slider__swiper-button-prev"
			},
			"autoplay": {
			"delay": 7000
			}}'>

							<div class="swiper-wrapper">
									<!--Start Single Swiper Slide-->
									<div class="swiper-slide">
											<div class="shape1"><img src="assets/new/images/shapes/slider-v1-shape1.png" alt="" /></div>
											<div class="shape2"><img src="assets/new/images/shapes/slider-v1-shape2.png" alt="" /></div>
											<div class="image-layer"
													style="background-image: url(assets/new/images/backgrounds/main-slider-v1-1.jpg);"></div>
											<!-- /.image-layer -->
											<div class="container">
													<div class="main-slider__content">
															<div class="main-slider__content-icon-one">
																	<span class="icon-lamp"></span>
															</div>
															<div class="main-slider__content-icon-two">
																	<span class="icon-human-resources"></span>
															</div>
															<div class="main-slider__content-tagline">
																	<h2>El espacio donde la nueva escuela, la vieja escuela y la no escuela convergen</h2>
															</div>
															<h2 class="main-slider__content-title">Libera tu<br>potencial</h2>
															<p class="main-slider__content-text">Intégrate a la plataforma No. 1 de habla hispana<br>&nbsp;especializada en cursos y talleres para start ups.</p>
															<div class="main-slider__content-btn">
																	<a [routerLink]="['/signin']" class="thm-btn">Únete a la Comunidad 4x4 Emprendedores Ahora</a>
															</div>
															<div class="main-slider-one__img">
																	<img src="assets/new/images/backgrounds/portada.png" alt="" />
															</div>
													</div>
											</div>
									</div>
									<!--End Single Swiper Slide-->
							</div>
					</div>
			</section>

			<!--Features One Start-->
			<section class="features-one">
					<div class="container">
							<div class="row">
									<!--Start Single Features One-->
									<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
											<div class="features-one__single">
													<div class="features-one__single-icon">
															<span class="icon-empowerment"></span>
													</div>
													<div class="features-one__single-text">
															<h4><a>Obten habilidades</a></h4>
															<p>Con Nuestros Cursos</p>
													</div>
											</div>
									</div>
									<!--End Single Features One-->

									<!--Start Single Features One-->
									<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
											<div class="features-one__single">
													<div class="features-one__single-icon">
															<span class="icon-human-resources-1"></span>
													</div>
													<div class="features-one__single-text">
															<h4><a>Capacitadores expertos</a></h4>
															<p>Altamente calificados</p>
													</div>
											</div>
									</div>
									<!--End Single Features One-->

									<!--Start Single Features One-->
									<div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="400ms" data-wow-duration="1500ms">
											<div class="features-one__single">
													<div class="features-one__single-icon">
															<span class="icon-recruitment"></span>
													</div>
													<div class="features-one__single-text">
															<h4><a>Certificados</a></h4>
															<p>Con valor internacional</p>
													</div>
											</div>
									</div>
									<!--End Single Features One-->
							</div>
					</div>
			</section>
			<!--Features One End-->        

			<!--Why Choose One Start-->
			<section class="why-choose-one">
					<div class="container">
							<div class="row">
									<!--Start Why Choose One Left-->
									<div class="col-xl-6 col-lg-6">
											<div class="why-choose-one__left">
													<div class="section-title">
															<span class="section-title__tagline">¿Por qué nosotros?</span>
															<h2 class="section-title__title">Beneficios de <br>my4x4academy</h2>
													</div>
													<div class="why-choose-one__left-learning-box">
															<div class="icon">
																	<span class="icon-professor"></span>
															</div>
															<div class="text">
																	<h4>Eleva tu capacidad para emprender o hacer crecer tu negocio.</h4>
															</div>
													</div>
													<div class="why-choose-one__left-list">
															<ul class="list-unstyled">
																	<li class="why-choose-one__left-list-single">
																			<div class="icon">
																					<span class="icon-confirmation"></span>
																			</div>
																			<div class="text">
																					<p style="font-size:22px;">Actualiza tus conocimientos y forma parte de la red de emprendedores de última generación en latinoamerica.</p>
																			</div>
																	</li>

															</ul>
													</div>
											</div>
									</div>
									<!--End Why Choose One Left-->

									<!--Start Why Choose One Right-->
									<div class="col-xl-6 col-lg-6">
											<div class="video-one__box slideInRight wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
											style="background-image: url(assets/new/images/resources/why-choose-v1-img.jpg);">
											<div class="video-one__box-title">
													<!-- <h2>Ver Video</h2> -->
													<div class="why-choose-one__right-img-overlay">
															<p>my4x4academy</p>
													</div>
											</div>
											<div class="icon wow zoomIn" data-wow-delay="300ms" data-wow-duration="1500ms">
													<a class="video-popup" title="Video"
															(click)="openDialogVideo('assets/new/images/video_1.mp4')">
															<span class="icon-play ml-6"></span>
													</a>
													<span class="border-animation border-1"></span>
													<span class="border-animation border-2"></span>
													<span class="border-animation border-3"></span>
											</div>
											</div>
									</div>
									<!--End Why Choose One Right-->

							</div>
					</div>
			</section>
			<!--Why Choose One End-->  

			<!--About One Start-->
			<section class="about-one clearfix" style="padding-top:120px;">
					<div class="container">
							<div class="row">
									<!--Start Why Choose One Right-->
									<div class="col-xl-6 col-lg-12">
											<div class="video-two__box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
											style="background-image: url(assets/new/images/backgrounds/video-2.jpg); background-position: center center; background-size: cover !important; ">
											<div class="video-two__box-title">
													<div class="about-one__left-overlay">
															<div class="icon">
																	<span class="icon-relationship"></span>
															</div>
															<div class="title">
																	<h6>+ <span class="odometer" data-count="6000">6000</span><br>Estudiantes<br>Activos</h6>
															</div>
													</div>
											</div>
											<div class="icon wow zoomIn" data-wow-delay="300ms" data-wow-duration="1500ms">
													<a class="video-popup" title="Video"
															(click)="openDialogVideo('assets/new/images/video_2.mp4')">
															<span class="icon-play ml-6"></span>
													</a>
													<span class="border-animation border-1"></span>
													<span class="border-animation border-2"></span>
													<span class="border-animation border-3"></span>
											</div>
											</div>
									</div>
									<!--End Why Choose One Right-->                    

									<!-- Start About One Right-->
									<div class="col-xl-6">
											<div class="about-one__right">
													<div class="section-title">
															<span class="section-title__tagline" style="font-size:4.3em; font-weight: bold; padding-top: 75px;">900,000</span>
															<h2 class="section-title__title" style="text-transform: none;">Emprendedores han tomado nuestros cursos a lo largo de 23 años.</h2>
													</div>
													<div class="about-one__right-inner">
															<p class="about-one__right-text">Te invitamos a conocer nuestro canal de You Tube para descubrir los casos de éxito, historias que inspiran y testimonios.</p>

															<div class="about-one__btn">
																	<a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank" class="thm-btn">Visítanos en YouTube</a>
															</div>
													</div>
											</div>
									</div>
									<!-- End About One Right-->
							</div>
					</div>
			</section>
			<!--About One End-->

			<!--Registration One Start-->
			<section class="registration-one jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%">
					<div class="registration-one__bg"
							style="background-image: url(assets/new/images/backgrounds/unete_a_la_comunidad.jpg);"></div>
					<div class="container">
							<div class="row">
									<!--Start Registration One Left-->
									<div class="col-xl-7 col-lg-7">
											<div class="registration-one__left">
													<div class="section-title">
															<span class="section-title__tagline">¡Regístrate ahora!</span>
															<h2 class="section-title__title" style="text-transform:none">Únete a la comunidad 4x4 Emprendedores Todo Terreno Hoy.</h2>
													</div>
													<p class="registration-one__left-text">Desarróllate y transformate con modelos futuristas de la nueva economía por menos de 5 dólares la hora.</p>
													<div class="registration-one__left-transform-box">
															<div class="registration-one__left-transform-box-icon">
																	<span class="icon-online-course"></span>
															</div>
															<div class="registration-one__left-transform-box-text">
																	<h3><a href="#">Transforma tus conocimientos</a></h3>
																	<p>Conoce e inscríbete en el curso que más se adapte a tus necesidades.</p>
															</div>
													</div>
											</div>
									</div>
									<!--End Registration One Left-->

									<!--Start Registration One Right-->
									<div class="col-xl-5 col-lg-5">
											<div class="registration-one__right wow slideInRight" data-wow-delay="100ms"
													data-wow-duration="2500ms">
													<div class="registration-one__right-form">
															<div class="title-box">
																	<h4>Regístrate</h4>
															</div>
															<div class="form-box">
																	<form [formGroup]="form" (ngSubmit)="onSubmit()">
																			<div class="form-group">
																				<input (input)="form.controls['name'].markAsTouched()" type="text" name="name" formControlName="name" placeholder="Nombre" required="">
																			</div>
																			<div class="form-group">
																				<small *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched" 
																				class="form-error-msg"> * Requerido</small>
																			</div>
																			<div class="form-group">
																				<input (input)="form.controls['lastname'].markAsTouched()" type="text" name="lastname" formControlName="lastname" placeholder="Apellidos" required="">
																			</div>
																			<div class="form-group">
																				<small *ngIf="form.controls['lastname'].hasError('required') && form.controls['lastname'].touched" 
																				class="form-error-msg"> * Requerido</small>
																			</div>
																			<div class="form-group">
																				<input (input)="form.controls['email'].markAsTouched()" type="email" name="email" formControlName="email" placeholder="Correo electrónico" required="">
																			</div>
																			<div class="form-group">
																				<small *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched" 
																				class="form-error-msg"> * Requerido</small>
																				<small *ngIf="form.controls['email'].hasError('email') && form.controls['email'].touched" 
																				class="form-error-msg"> * Correo electrónico no válido.</small>
																			</div>
																			<button class="registration-one__right-form-btn" type="submit"
																					name="submit-form">
																					<span class="thm-btn">Enviar</span>
																			</button>
																	</form>
															</div>
													</div>
											</div>
									</div>
									<!--End Registration One Right-->
							</div>
					</div>
			</section>
			<!--Registration One End-->        

			<!--Video One Start-->
			<section class="video-three">
					<div class="container">
							<div class="row">
									<div class="col-xl-12">
											<div class="video-three__box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms"
													style="background-image: url(assets/new/images/backgrounds/video-v1-bg.jpg); background-repeat: no-repeat; background-size:cover; background-position: center;" >
													<div class="icon wow zoomIn" data-wow-delay="300ms" data-wow-duration="1500ms">
															<a class="video-popup" title="Video"
																	(click)="openDialogVideo('assets/new/images/video_3.mp4')">
																	<span class="icon-play ml-6"></span>
															</a>
															<span class="border-animation border-1"></span>
															<span class="border-animation border-2"></span>
															<span class="border-animation border-3"></span>
													</div>
											</div>
									</div>
							</div>
					</div>
			</section>
			<!--Video One End-->

			<!--Counter One Start-->
			<section class="counter-one jarallax" data-jarallax data-speed="0.2" data-imgPosition="50% 0%"
					style="background-image: url(assets/new/images/backgrounds/Counters-v1-bg.jpg);">
					<div class="container">
							<div class="row">
									<!--Start Counter One Left-->
									<div class="col-xl-5 col-lg-5">
											<div class="counter-one__left">
													<div class="section-title">
															<h2 class="section-title__title"><br>Negociación</h2>
													</div>
													<p class="counter-one__left-text">Vinculación de la experiencia obtenida en la sesión digital con casos y ejemplos de la vida real.</p>
											</div>
									</div>
									<!--End Counter One Left-->

									<!--Start Counter One Right-->
									<div class="col-xl-7 col-lg-7">
											<div class="counter-one__right">
													<ul class="counter-one__right-box list-unstyled">
															<!--Start Counter One Right Single-->
															<li class="counter-one__right-single wow slideInUp animated" data-wow-delay="0.1s"
																	data-wow-duration="1500ms">
																	<div class="counter-one__right-single-icon">
																			<span class="icon-teacher" style="color:#fff;"></span>
																	</div>
																	<h3 class="odometer" data-count="50">50</h3>
																	<p class="counter-one__right-text">Capacitadores</p>
															</li>
															<!--End Counter One Right Single-->

															<!--Start Counter One Right Single-->
															<li class="counter-one__right-single wow slideInUp animated" data-wow-delay="0.3s"
																	data-wow-duration="1500ms">
																	<div class="counter-one__right-single-icon">
																			<span class="icon-online-course" style="color:#fff;"></span>
																	</div>
																	<h3 class="odometer" data-count="4">04</h3>
																	<p class="counter-one__right-text">Cursos</p>
															</li>
															<!--End Counter One Right Single-->

															<!--Start Counter One Right Single-->
															<li class="counter-one__right-single wow slideInUp animated" data-wow-delay="0.5s"
																	data-wow-duration="1500ms">
																	<div class="counter-one__right-single-icon">
																			<span class="icon-student" style="color:#fff;"></span>
																	</div>
																	<h3 class="odometer" data-count="8000">8,000</h3>
																	<p class="counter-one__right-text">Estudiantes actuales</p>
															</li>
															<!--End Counter One Right Single-->
													</ul>
											</div>
									</div>
									<!--End Counter One Right-->
							</div>
					</div>
			</section>
			<!--Counter One End-->    


			<!--Courses One Start-->
			<section class="courses-one" style="padding-top:120px" id="cursos">
					<div class="container">
							<div class="section-title text-center">
									<h2 class="section-title__title">Nuestros Cursos</h2>
							</div>
							<!-- courses-container -->
							<div class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}" style="justify-content: center;align-items: center;">
								<!-- Course Box -->
								<div class="product-wrap product-wrap-details m-1 course-container"
									*ngFor="let course of courses; let i = index;"
									[@animate]="{value:'*',params:{delay: (i*100)+'ms', y:'50px'}}">
									<div fxLayout="column" fxFlex="100">
										<div 
											fxFlex="100" 
											fxLayout="column" 
											fxLayoutAlign="center center" 
											class="courses-one__single wow fadeInLeft"
											[routerLink]="['/studyPlan',course.courseSlug]"
											>
											<div class="courses-one__single-img">
												<img [src]="course.imageUrl" alt="" />
												<div class="overlay-text" style="cursor: pointer;">
													<p [routerLink]="activo ? ['/dashboard/cursos'] : [COURSES_NO_AUTH_ROUTE]">Ver detalles</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- end course box -->
							</div>
					</div>
			</section>
			<!--Courses One End-->

			<!--Testimonials One Start-->
			<section *ngIf="testimonials.length > 0" class="testimonials-one clearfix" style="height: 400px;margin-top: 10vh;">
				<div class="auto-container">
						<div class="section-title text-center">
								<span class="section-title__tagline">Testimonios</span>
								<h2 class="section-title__title"> Lo que se dice</h2>
						</div>
						<div class="row">
								<div class="col-xl-12">
										<div class="testimonials-one__wrapper">
												<div class="testimonials-one__pattern"><img
																src="assets/new/images/pattern/testimonials-one-left-pattern.png" alt="" /></div>
												<div class="shape1"><img src="assets/new/images/shapes/thm-shape3.png" alt="" /></div>
										</div>
								</div>
						</div>
				</div>
			</section>
			<div *ngIf="testimonials.length > 0" class="product-container" [ngClass]="{'list-view': viewMode === 'list-view'}" style="justify-content: center;align-items: center;transform: translate(0px,-15vh);">
				<!-- Course Box -->
				<div style="margin-bottom: 2vh;"
							*ngFor="let t of testimonials | paginate: { id:'pagination',itemsPerPage: itemsPerPageP1, currentPage: currentPageP1, totalItems: rowsNumberP1 }; let i = index;"
							[@animate]="{value:'*',params:{delay: (i*300)+'ms', y:'50px'}}">
							<div fxLayout="column" fxFlex="100" class="testimonial-container">
								<div 
									fxFlex="100" 
									fxLayout="column" 
									fxLayoutAlign="center start" class="testimonial-content">
									<h4 class="testimonials-one__single-title">{{t?.title}}</h4>
									<div class="meet-teachers-one__single-img_2">
										<img src="{{t?.imageUrl}}" alt=""/>
									</div>
									<p class="testimonials-one__single-text">{{t?.testimonial}}</p>
									<div class="testimonials-one__single-client-info">
											<div class="testimonials-one__single-client-info-text">
													<h5>{{t?.author}}</h5>
													<p>{{t?.ocupation}}</p>
											</div>
									</div>
								</div>
							</div>
							
				</div>
			</div>
			<div *ngIf="testimonials.length > 0" class="text-center" style="min-height:120px;transform: translate(0px,-15vh)">
				<pagination-controls id="pagination" (pageChange)="changePagePaginator1($event)" previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
			</div>
			<!--Testimonials One End-->

			<!--Start Registration Two-->
			<section class="registration-two">
					<div class="container">
							<div class="row">
									<div class="col-xl-12">
											<div class="registration-two__wrapper">
													<div class="shape2 wow slideInRight" data-wow-delay="100ms" data-wow-duration="2500ms"><img
																	src="assets/new/images/shapes/thm-shape3.png" alt="" /></div>
													<div class="registration-two__left">
															<h2 class="registration-two__left-text" style="text-transform: none;">Alcancemos el éxito juntos <br>my4x4academy</h2>
													</div>
													<div class="registration-two__right">
															<div class="registration-two__right-btn">
																	<a [routerLink]="['/signup']" class="thm-btn">Registrate</a>
															</div>
													</div>
											</div>
									</div>

							</div>
					</div>
			</section>
			<!--End Registration Two-->    
									

			<!--Start Footer One-->
			<footer *ngIf="!activo" class="footer-one">
					<div class="footer-one__bg" style="background-image: url(assets/new/images/backgrounds/footer.jpg);">
					</div><!-- /.footer-one__bg -->
					<div class="footer-one__top">
							<div class="container">
									<div class="row">
											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.1s">
													<div class="footer-widget__column footer-widget__about">
															<div class="footer-widget__about-logo">
																	<a href="index.html"><img src="assets/new/images/resources/footer-logo.png" alt=""></a>
															</div>
													</div>
											</div>
											<!--End Footer Widget Column-->
									

											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
													<div class="footer-widget__column footer-widget__courses">
															<h3 class="footer-widget__title">Enlaces útiles</h3>
															<ul class="footer-widget__courses-list list-unstyled">
																	<li><a href="#">Fundación E</a></li>
																	<li><a href="#">4x4 Emprendedores</a></li>

															</ul>
													</div>
											</div>
											<!--End Footer Widget Column-->

											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.5s">
													<div class="footer-widget__column footer-widget__links">
															<h3 class="footer-widget__title">Legales</h3>
															<ul class="footer-widget__links-list list-unstyled">
																<li><a (click)="(isLogged)?openDialogPrivacy():services.goTo('/privacy')">Aviso de privacidad</a></li>
                          			<li><a (click)="(isLogged)?openDialogPrivacy():services.goTo('/privacy')">Términos y condiciones</a></li>
															</ul>
													</div>
											</div>
											<!--End Footer Widget Column-->


											<!--Start Footer Widget Column-->
											<div class="col-xl-3 col-lg-6 col-md-6 col-12 wow animated fadeInUp" data-wow-delay="0.9s">
													<div class="footer-widget__column footer-widget__social-links">
															<ul class="footer-widget__social-links-list list-unstyled clearfix">
																	<li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
																	<li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
																	<li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
																	<li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
															</ul>
													</div>
											</div>
											<!--End Footer Widget Column-->
									</div>
							</div>
					</div>

					<!--Start Footer One Bottom-->
					<div class="footer-one__bottom">
							<div class="container">
									<div class="row">
											<div class="col-xl-12">
													<div class="footer-one__bottom-inner">
															<div class="footer-one__bottom-text text-center">
																<p class="text-center">
																	&copy; Todos los derechos reservados 2023 - 2033.
																	<br/>Ninguna parte de este material puede ser reproducido, copiado, distribuido o replicado sin el permiso por escrito de Fundación Educación para Emprendedores AC.
																	<br/>My 4x4 Academy es una empresa de Q22 Global SA de CV
																</p>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
					<!--End Footer One Bottom-->
			</footer>
			<!--End Footer One-->


	</div><!-- /.page-wrapper -->



	<div class="mobile-nav__wrapper">
			<div class="mobile-nav__overlay mobile-nav__toggler"></div>
			<!-- /.mobile-nav__overlay -->
			<div class="mobile-nav__content">
					<span class="mobile-nav__close mobile-nav__toggler"><i class="fa fa-times"></i></span>

					<div class="logo-box">
							<a href="index.html" aria-label="logo image"><img src="assets/new/images/resources/mobilemenu_logo.png"
											width="155" alt="" /></a>
					</div>
					<!-- /.logo-box -->
					<div class="mobile-nav__container"></div>
					<!-- /.mobile-nav__container -->

					<ul class="mobile-nav__contact list-unstyled">
							<li>
									<i class="icon-phone-call"></i>
									<a href="mailto:needhelp@packageName__.com">atencion@fundacione.org</a>
							</li>
							<li>
									<i class="icon-letter"></i>
									<a href="tel:666-888-0000">666 888 0000</a>
							</li>
					</ul><!-- /.mobile-nav__contact -->
					<div class="mobile-nav__top">
							<div class="mobile-nav__social">
									<li><a href="https://www.facebook.com/4x4Emprendedores/" target="_blank"><i class="fab fa-facebook"></i></a></li>
									<li><a href="https://www.instagram.com/4x4emprendedores/" target="_blank"><i class="fab fa-instagram"></i></a></li>
									<li><a href="https://www.youtube.com/channel/UCK4nuGlFDZhywzxZdWfNoOw" target="_blank"><i class="fab fa-youtube"></i></a></li>
									<li><a href="https://www.linkedin.com/company/fundacion-e/" target="_blank"><i class="fab fa-linkedin"></i></a></li>
							</div><!-- /.mobile-nav__social -->
					</div><!-- /.mobile-nav__top -->
			</div>
			<!-- /.mobile-nav__content -->
	</div>
	<!-- /.mobile-nav__wrapper -->


	<a (click)="gotoTop()" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
</div>